<template>
  <div class="page-header">会社概要</div>
  <Breadcrumb menu="会社概要" />
  <div class="content">
    <dl>
      <dd>運営会社</dd>
      <dt>株式会社アスラク</dt>
    </dl>
    <dl>
      <dd>設立</dd>
      <dt>2020年2月</dt>
    </dl>
    <dl>
      <dd>事業概要</dd>
      <dt>
        仕入れ代行<br>
        ECサイト運営<br>
        システム開発
      </dt>
    </dl>
    <dl>
      <dd>所在地</dd>
      <dt>
        <p>
          〒120-0006
          <br>
          東京都足立区谷中1-9-2-204
        </p>
      </dt>
    </dl>
  </div>
</template>

<script>
import Breadcrumb from '../components/atoms/Breadcrumb.vue'

export default {
  name: 'News',
  components: {
    Breadcrumb,
  },
  data() {
  },
}
</script>

<style lang="scss">
.page-header {
  background: #F7F7F7;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-top: #cee7f0 1px solid;
  color: black;
  justify-content: center;
  height: 100px;
}

.content {
  margin: auto;
  padding-top: 65px;
  min-height: 50vh;
  max-width: 800px;
  padding-bottom: 100px;
  dl {
    display: flex;
    border-bottom: 1px solid rgb(231, 222, 222);
    padding: 10px 5px 20px 5px;
    align-items: center;
    dd {
      flex: 1;
      text-align: center;
      font-weight: bold;
    }
    dt {
      flex: 3;
      text-align: center;
    }
  }
}

@media (min-width: 751px) {
  .content {
    width: 80%;
  }
}

@media screen and (max-width: 750px) {
  .content {
    width: 90%;
  }
  dl {
    dd {
      margin-inline-start: 10px;
    }
  }
}
</style>